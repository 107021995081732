<template>
  <el-row>
    <el-col :span="24">
      <el-col v-for="(item, index) in showList" :key="index" :span="8">
        <div class="getOther" v-if="index <= 1">
          <div
            v-if="
              item.type == 'input' ||
              item.type == 'text' ||
              item.type == 'textarea' ||
              item.type == 'number'
            "
            class="contents"
          >
            <div>{{ item.label }}</div>
            <div>
              <el-input
                clearable
                style="width: 100%"
                size="small"
                v-model="item.value"
                placeholder="请输入"
              ></el-input>
            </div>
          </div>

          <div
            v-if="item.type == 'select' && item.status && showAuditStatus"
            class="contents"
          >
            <div>{{ item.label }}</div>
            <div>
              <el-select
                clearable
                collapse-tags
                multiple
                style="width: 100%"
                size="small"
                v-model="item.value"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in item.options"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div v-if="item.type == 'select' && !item.status" class="contents">
            <div>{{ item.label }}</div>
            <div>
              <el-select
                clearable
                collapse-tags
                :multiple="item.multiple != 'false'"
                style="width: 100%"
                size="small"
                v-model="item.value"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in item.options"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div
            v-if="
              item.type == 'checkbox' ||
              item.type == 'radio' ||
              item.type == 'selects'
            "
            class="contents"
          >
            <div>{{ item.label }}</div>
            <div>
              <el-select
                clearable
                collapse-tags
                multiple
                style="width: 100%"
                size="small"
                v-model="item.value"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in item.options"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div v-if="item.type == 'time'" class="contents">
            <div>{{ item.label }}</div>
            <div>
              <el-date-picker
                clearable
                size="small"
                style="width: 100%"
                v-model="item.value"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
          </div>

          <div v-if="item.type == 'cascader'" class="contents">
            <div>{{ item.label }}</div>
            <div>
              <el-cascader
                collapse-tags
                clearable
                style="width: 100%"
                :options="item.optionsList"
                v-model="item.value"
                separator=">"
                size="small"
                class="form-input"
                :props="item.props"
              ></el-cascader>
            </div>
          </div>
        </div>
      </el-col>

      <el-col align="center" class="btns" :span="8">
        <el-popover
          v-if="msgAll.length != 0"
          placement="bottom"
          trigger="click"
          :width="msgAll.length > 4 ? 860 : msgAll.length * 220"
        >
          <span slot="reference">
            <el-link style="padding: 0 10px" :underline="false" type="primary">
              数据统计
            </el-link>
          </span>

          <div class="others">
            <div v-for="(item, index) in msgAll" :key="index">
              <div :style="{ 'background-color': item.color || '#0088fe' }">
                <i class="el-icon-s-data"></i>
              </div>
              <div>
                <p :style="{ color: item.color || '#0088fe' }">
                  {{ item.number }}
                </p>
                <p>{{ item.name }}</p>
              </div>
            </div>
          </div>
        </el-popover>

        <el-button @click="clickEmpty" size="small">清空</el-button>
        <el-button @click="serchForm" type="danger" size="small"
          >搜索</el-button
        >
        <span>
          <el-link
            @click="showMores = !showMores"
            style="padding: 0 10px"
            :underline="false"
            type="danger"
          >
            高级搜索
            <i
              :class="showMores ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
            ></i>
          </el-link>
        </span>
      </el-col>
    </el-col>

    <el-collapse-transition>
      <el-col v-show="showMores" :span="24">
        <el-col v-for="(item, index) in showList" :key="index" :span="8">
          <div class="getOther" v-if="index > 1">
            <div
              v-if="
                item.type == 'input' ||
                item.type == 'text' ||
                item.type == 'textarea' ||
                item.type == 'number'
              "
              class="contents"
            >
              <div>{{ item.label }}</div>
              <div>
                <el-input
                  clearable
                  style="width: 100%"
                  size="small"
                  v-model="item.value"
                  placeholder="请输入"
                ></el-input>
              </div>
            </div>
            <!-- autocomplete -->

            <div v-if="item.type == 'autocomplete'" class="contents">
              <div>{{ item.label }}</div>
              <div>
                <el-select
                  style="width: 100%"
                  size="small"
                  v-model="item.value"
                  clearable
                  collapse-tags
                  multiple
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入名字"
                  :remote-method="remoteMethod"
                  :loading="loading"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div v-if="item.type == 'autocompleteName'" class="contents">
              <div>{{ item.label }}</div>
              <div>
                <el-select
                  style="width: 100%"
                  size="small"
                  v-model="item.value"
                  clearable
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入名字"
                  :remote-method="remoteMethod"
                  :loading="loading"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div
              v-if="item.type == 'select' && item.status && showAuditStatus"
              class="contents"
            >
              <div>{{ item.label }}</div>
              <div>
                <el-select
                  clearable
                  collapse-tags
                  :multiple="item.multiple != 'false'"
                  style="width: 100%"
                  size="small"
                  v-model="item.value"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in item.options"
                    :key="index"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div v-if="item.type == 'select' && !item.status" class="contents">
              <div>{{ item.label }}</div>
              <div>
                <el-select
                  clearable
                  collapse-tags
                  :multiple="item.multiple != 'false'"
                  style="width: 100%"
                  size="small"
                  v-model="item.value"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in item.options"
                    :key="index"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div
              v-if="
                item.type == 'checkbox' ||
                item.type == 'radio' ||
                item.type == 'selects'
              "
              class="contents"
            >
              <div>{{ item.label }}</div>
              <div>
                <el-select
                  clearable
                  collapse-tags
                  multiple
                  style="width: 100%"
                  size="small"
                  v-model="item.value"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in item.options"
                    :key="index"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div v-if="item.type == 'time'" class="contents">
              <div>{{ item.label }}</div>
              <div>
                <el-date-picker
                  clearable
                  size="small"
                  style="width: 100%"
                  v-model="item.value"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </div>
            </div>

            <div v-if="item.type == 'date'" class="contents">
              <div>{{ item.label }}</div>
              <div>
                <el-date-picker
                  clearable
                  size="small"
                  style="width: 100%"
                  v-model="item.value"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </div>
            </div>

            <div v-if="item.type == 'cascader'" class="contents">
              <div>{{ item.label }}</div>
              <div>
                <el-cascader
                  collapse-tags
                  clearable
                  style="width: 100%"
                  :options="item.optionsList"
                  v-model="item.value"
                  separator=">"
                  size="small"
                  class="form-input"
                  :props="item.props"
                ></el-cascader>
              </div>
            </div>
          </div>
        </el-col>
      </el-col>
    </el-collapse-transition>
  </el-row>
</template>

<script>
export default {
  /*
     @search  返回参数信息
   * 搜索设置
   * model :[{
   * 	field:'', // 字段名称
   *  label:'', //label 显示
   *  value:'', // 绑定值
   * 	custom:'', // 是否为自定义字段，如果是 返回该对象
   *  type:'',  //searList拉多选
   *  status:true,  type == select 时候必填，是否为各个模块的状态标志，
   *  options:[{name:'',value:''}], //type为select 必填1
   *  optionsList: [], // type为cascader 必填
   *  placeholder:'',  //输入框占位符 选填
   *  props: {
   * 		checkStrictly: true, //  来设置父子节点取消选中关联
   * 		multiple: true, // 是否多选
   * 		value: 'id', // 传递的参数
   * 		label: '', // 显示的title
   * 	}
   * }]
   * showAuditStatus: 各个模块的状态是否显示
   * customer  :{ 
   *              app_id: 4,
                  app_modular: "phm_model",
                 }

   * msgAll:{   // 数据统计
         name: "全部规程",
         number: "162",
         color: "#0088fe",
       },
       {
         name: "全部规程",
         number: "162",
         color: "green",
       }, 
   * 
   */
  props: {
    showAuditStatus: {
      type: Boolean,
      default: true, // true 显示。 false 不显示
    },
    model: {
      // 这里是搜索字段列表
      type: Array,
      default: () => {
        return [];
      },
    },
    customer: {
      type: Object,
      default: () => {
        return null;
      },
    },
    msgAll: {
      // 这里是数据统计
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  name: "serchHeader",
  data() {
    return {
      showMores: false,
      array: [],
      restaurants: [],
      options: [],
      loading: false,
      showList: [],
    };
  },
  watch: {
    "model.length"() {
      this.getInputList();
      console.log("this.model==>", this.model);
    },
  },
  created() {
    this.getInputList();
  },
  methods: {
    getInputList() {
      if (this.customer) {
        let params = {
          ...this.customer,
          is_all: 1,
          show_type: 1,
        };
        this.$axios
          .get("/api/field/list", params)
          .then((res) => {
            if (res.code == 200) {
              let data = res.data;
              data = data.filter((item) => {
                return item.is_search == 1;
              });
              let searchArr = [];
              data.map((item) => {
                let type = item.type;
                let obj = {
                  label: item.name,
                  placeholder:
                    item.prompt || (type == "input" ? "请输入" : "请选择"),
                  field: item.code,
                  type: type,
                  custom: !0,
                  custom_field_id: item.id,
                  value: "",
                };
                if (
                  type == "select" ||
                  type == "selects" ||
                  type == "checkbox" ||
                  type == "radio"
                ) {
                  let optArr = [];
                  item.selected.map((item) => {
                    optArr.push({
                      name: item.val,
                      value: item.val,
                    });
                    let obj = {};

                    obj[item.code] = item;
                  });
                  obj.options = optArr;
                  obj.value = [];
                }
                searchArr.push(obj);
              });
              console.log(searchArr);
              this.showList = [...this.model, ...searchArr];
            }
          })
      } else {
        this.showList = [...this.model];
      }
    },

    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        let array = [];
        let params = {
          name: query,
          page_size: 100,
          page: 1,
        };
        this.$axios.postJSON("/base/staff/list", params).then(
          (res) => {
            if (res.code == 200) {
              res.data.list.forEach((item) => {
                array.push({
                  value: item.id,
                  label: item.name,
                });
              });
              this.options = array;
              this.loading = false;
            }
          },
          (err) => {
            this.loading = false;
            this.options = this.options;
          }
        );
      } else {
        this.options = this.options;
      }
    },
    dataForm() {
      let array = JSON.parse(JSON.stringify(this.showList));
      let obj = {};
      let customr = [];
      array.forEach((items) => {
        if (items.custom) {
          if (items.value) {
            if (
              Object.prototype.toString.call(items.value) == "[object Array]" &&
              items.value.length == 0
            ) {
            } else {
              if (items.type == "date") {
                customr.push({
                  custom: true,
                  custom_field_id: items.custom_field_id,
                  code: items.field,
                  label: items.label,
                  input_type: items.type,
                  input_value: {
                    start_time: items.value[0],
                    end_time: items.value[1],
                  },
                });
              } else {
                customr.push({
                  custom: true,
                  custom_field_id: items.custom_field_id,
                  code: items.field,
                  label: items.label,
                  input_type: items.type,
                  input_value: items.value,
                });
              }
            }
          }
        } else {
          if (items.value || items.value === 0) {
            if (
              Object.prototype.toString.call(items.value) == "[object Array]" &&
              items.value.length == 0
            ) {
            } else {
              obj[items.field] = items.value;
              if (items.type == "time") {
                obj[items.field] = {
                  start_time: items.value[0],
                  end_time: items.value[1],
                };
              }
            }
          }
        }
      });
      if (!this.showAuditStatus) {
        delete obj.audit_status;
      }
      if (customr.length != 0) {
        obj.customer = customr;
      }
      return obj
    },
    serchForm() {
      let array = JSON.parse(JSON.stringify(this.showList));
      let obj = {};
      let customr = [];
      array.forEach((items) => {
        if (items.custom) {
          if (items.value) {
            if (
              Object.prototype.toString.call(items.value) == "[object Array]" &&
              items.value.length == 0
            ) {
            } else {
              if (items.type == "date") {
                customr.push({
                  custom: true,
                  custom_field_id: items.custom_field_id,
                  code: items.field,
                  label: items.label,
                  input_type: items.type,
                  input_value: {
                    start_time: items.value[0],
                    end_time: items.value[1],
                  },
                });
              } else {
                customr.push({
                  custom: true,
                  custom_field_id: items.custom_field_id,
                  code: items.field,
                  label: items.label,
                  input_type: items.type,
                  input_value: items.value,
                });
              }
            }
          }
        } else {
          if (items.value || items.value === 0) {
            if (
              Object.prototype.toString.call(items.value) == "[object Array]" &&
              items.value.length == 0
            ) {
            } else {
              obj[items.field] = items.value;
              if (items.type == "time") {
                obj[items.field] = {
                  start_time: items.value[0],
                  end_time: items.value[1],
                };
              }
            }
          }
        }
      });
      if (!this.showAuditStatus) {
        delete obj.audit_status;
      }
      if (customr.length != 0) {
        obj.customer = customr;
      }
      this.$emit("search", obj);
    },
    clickEmpty() {
      this.showList.forEach((ites) => {
        if (["selects", "cascader", "autocomplete"].includes(ites.type)) {
          ites.value = [];
        } else if (ites.type == "select") {
          if (ites.multiple == "false") {
            ites.value = "";
          } else {
            ites.value = [];
          }
        } else if (ites.type == "time") {
          ites.value = "";
        } else {
          ites.value = "";
        }
      });
      this.$emit("clickEmpty");
    },
    empty() {
      this.showList.forEach((ites) => {
        if (
          ["select", "selects", "cascader", "autocomplete"].includes(ites.type)
        ) {
          ites.value = [];
        } else if (ites.type == "time") {
          ites.value = "";
        } else {
          ites.value = "";
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contents {
  display: flex;
  line-height: 32px;
  margin-bottom: 19px;
  & > div:nth-child(1) {
    width: 140px;
    padding-right: 10px;
    box-sizing: border-box;
    text-align: right;
  }
  & > div:nth-child(2) {
    flex: 1;
  }
}
.others {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 1px;
  & > div {
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
    width: 200px;
    display: flex;
    background-color: #fafafa;
    & > div:nth-child(1) {
      border-radius: 10px;
      width: 50px;
      height: 50px;
      padding: 5px;
      margin-top: 5px;
      margin-right: 10px;
      i {
        font-size: 40px;
        line-height: 40px;
        color: #fff;
      }
    }
    & > div:nth-child(2) {
      & > p:nth-child(1) {
        user-select: none;
        font-weight: 600;
        line-height: 35px;
        color: #666;
        font-size: 18px !important;
      }
      & > p:nth-child(1) {
        user-select: none;
        font-size: 12px;
      }
    }
  }
}
.getOther {
  & > div {
    height: 28px;
  }
}
</style>