<!-- 
通用分页
接收 pages：{
        pageNum:1,
        pageSize:10,
        total:100
    }
返回 @changePage  {type:'size',num:val}
 -->
<template>
  <el-row class="pageNom">
    <el-col :align="align" :span="24">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.total"
      >
      </el-pagination>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props:{
    align:{
      type: String,
      default:'right',
    },
    pages:{
      type:Object,
      default:() => {
        return null
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.$emit("changePage", { type: "size", num: val });
    },
    handleCurrentChange(val) {
      this.$emit("changePage", { type: "page", num: val });
    },
  },
};
</script>

<style lang="scss" scoped>
.pageNom {
  padding: 20px 0;
  box-sizing: border-box;
}
</style>