<template>
	<div>
		<el-tooltip
				:disabled="text && text.length< length"
				effect="light"
				:content="text"
				placement="top-start" 
			><div class="ellipsis-text">
				{{text}}
			</div>
		</el-tooltip>	
	</div>
</template>

<script>
	export default {
		props:{
			text:{
				type:String|Number,
				default:'',
			},
			length:{
				type:String|Number,
				default:15,
			}
		},
		data(){
			return {
				id: +new Date(),
				textWidth:50,
			}
		},
		created(){
			
		},
		mounted(){
			
		},
		methods: {
			
		},
	}
</script>

<style scoped lang="scss">
.ellipsis-text{
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}	
</style>
